import React from "react";
import Tilt from "react-tilt";
import FAQ from "../Pages/FAQ";

import Menu from "./Navbar";
import Feature from "./Feature";
import Price from "./Price";
import Footer from "./Footer";

import { Link } from "react-scroll";

import dict from "../utils/dictionary";
import { Helmet } from "react-helmet";

const Home = () => {
  const dictionary = dict()["home"];

  return (
    <div>
      <Helmet>
        {" "}
        <title>{dictionary["pageTitle"]}</title>
      </Helmet>

      <Menu homePage="home-one" />

      <section className="back-img ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-xl-5 offset-xl-2">
              <div className="home-contain">
                <div className="fadeInLeft-land-caption" style={{ marginTop: "-80px" }}>
                  {/* <img src={`${process.env.PUBLIC_URL}/assets/images/landing-logo.png`} alt="logo" className="main-logo" /> */}
                  <h1 style={{ lineHeight: "1.3", fontWeight: "600", fontSize: "2.5rem" }}>{dictionary["mainTextHomePage"]}</h1>
                  <Link href="#" activeClass="active" to={"price"} spy={true} smooth={true} offset={-50} duration={500}>
                    <div className="btn btn-custom" style={{ backgroundColor: "rgb(187 4 115)" }}>
                      <span>{dictionary["normalAccountsButtonText"]}</span>
                    </div>
                  </Link>
                  <a href="/skins" style={{ marginLeft: "20px" }}>
                    <div style={{ backgroundColor: "rgb(187 4 115)" }} className="btn btn-custom theme-color skin-accs-btn">
                      <span>{dictionary["skinsAccountsButtonText"]}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 sm-d-none">
              {/* <HireUs /> */}
              <div className="home-contain fadeIn-mac">
                <Tilt
                  options={{
                    perspective: 110,
                    speed: 400,
                    max: 1.2,
                    scale: 1,
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/mundo.webp`} alt="mac" className="img-fluid" style={{ maxWidth: "200%" }} />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Feature />

      <Price />

      <FAQ />
      <div className="skins-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-text-center d-align-center">
              {/* <h2 className='title' style={{ justifyContent: 'center', display: 'flex', color: '#dadada' }}>
								<span></span>
							</h2> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
