export default () => {
  const LANGUAGE = "arg";

  const dictionary = {
    arg: {
      // 404
      404: {
        notFound: "No encontrado",
        pageMovedOrNeverExisted: "La página que estas buscando fue movida o nunca existió!",
        backToMainPageButton: "Volver a página principal",
      },
      // SUCCESS
      success: {
        title: "Muchas gracias por tu compra!",
        orderIdText: "número de orden:",
        firstDesc: "Recibirás un email con tu compra en la brevedad.",
        secondDesc: "Guarda el número por si tienes problemas en el futuro.",
        backToMainPageButton: "Volver a página principal",
      },
      // FAILED
      failed: {
        title: "El pago ha sido rechazado!",
        orderIdText: "",
        firstDesc: "Tu pago fue cancelado o rechazado, por favor intenta devuelta.",
        secondDesc: "",
        backToMainPageButton: "Volver a página principal",
      },
      // PENDING
      pending: {
        title: "Tu pago está pendiente!",
        orderIdText: "número de orden:",
        firstDesc: "Cuando realices el pago recibirás tu compra por email.",
        secondDesc: "Guarda el número de orden por si tienes problemas en el futuro.",

        backToMainPageButton: "Volver a página principal",
      },
      // MENU
      menu: {
        home: "home",
        about: "nosotros",
        accounts: "cuentas",
        faq: "faq",
        skins: "skins",
        contact: "contacto",
        giveawaysText: "sorteos",
        giveawaysURL: "",
      },
      // HOME
      home: {
        pageTitle: "Arcane Smurfs - Compra tu cuenta unranked",
        mainTextHomePage: "¡La solución definitiva para adquirir tus Cuentas Unranked SIN BANEOS!",
        normalAccountsButtonText: "Cuentas normales",
        skinsAccountsButtonText: "Cuentas con skins",
      },
      // FEATURE
      feature: {
        whyChooseUs: "por qué elegir",
        storeName: "Arcane Smurfs",
        features: {
          1: {
            iconClass: "fas fa-search-dollar",
            title: "No más búsquedas.",
            text: "Encontraste el lugar correcto. Acá en Arcane Smurfs ofrecemos Smurfs LoL de alta calidad, manteniendo un precio bajo para garantizar que el cliente se mantenga feliz. Si probaste con otros competidores y notaste su falta de apoyo, seremos tu zona de comfort. ¡Estamos acá para vos!",
          },
          2: {
            iconClass: "fas fa-check",
            title: "Baja tasa de baneos.",
            text: "Nuestras cuentas se crean con un método privado para garantizar que la tasa de baneos se mantenga EXTREMADAMENTE baja. Esto es para asegurar la satisfacción de nuestros clientes, incluso al costo adicional que gastamos por ello.",
          },
          3: {
            iconClass: "fas fa-envelope-square",
            title: "Acceso completo.",
            text: 'Cuando nos compres una cuenta, será 100% tuya. Nadie más tendrá acceso a ella, nunca tendrás que preocuparte de que un "propietario original" la reclame como sucede con otros sitios.',
          },
          4: {
            iconClass: "fas fa-shipping-fast",
            title: "Entrega instantánea.",
            text: "¡No perdemos el tiempo! Una vez que hayas realizado tu compra, los detalles de la cuenta se te enviarán automáticamente por correo electrónico.",
          },
          5: {
            iconClass: "fas fa-heart",
            title: "Garantía de 7 dias.",
            text: "Ofrecemos una garantía de reemplazo de 7 dias en caso de que algo le suceda a tu cuenta. No cumplimos con esto si es baneada por tus acciones: mal comportamiento, abandonar partidas, etc.",
          },
          6: {
            iconClass: "fas fa-balance-scale-right",
            title: "Calidad sobre cantidad.",
            text: "Estamos orgullosos de la calidad de nuestras cuentas. Todas las cuentas tienen juegos de calidad y vienen con la mayor cantidad posible de BE según su tipo.",
          },
        },
      },
      // FEATURE
      price: {
        countryMpagoScript: "ar",
        title: "Cuentas",
        chooseYourRegion: "Elige tu región",
        regions: {
          las: "Latinoamérica Sur",
          br: "Brasil",
        },

        writeValidEmail: "Escribe un email válido",
        generatePayment: "Generar pago",
        continue: "Continuar",
        noStock: "Sin stock",
        errorTryAgain: "Error, intenta devuelta",
        errorNoStock: "ERROR: No hay stock disponible.",
        errorPleaseTryAgain: "ERROR: Por favor intenta otra vez.",
        errorCouponInvalidForQuantity: "ERROR: Cupón inválido para la cantidad del producto.",
        errorCouponExpired: "ERROR: Cupón ha expirado.",
        errorCouponDontExist: "ERROR: Cupón no existe para el producto seleccionado.",
        localCurrency: "pesos argentinos",
        localCurrencySymbol: "$",
        chooseButton: "Elegir",
        accountsTitle: "Cuentas",
        makeYourPayment: "Realiza tu pago",
        price: "Precio",
        quantity: "Cantidad",
        coupon: "Cupón",
        use: "Usar",
        useCouponToGetDiscount: "Usa un cupon para obtener un descuento",
        yourEmail: "Tu email",
      },
      faq: {
        title: "faq",
        1: {
          iconClass: "icon fas fa-shield-alt",
          title: "¿Cómo sé que es seguro comprar en Arcane Smurfs?",
          text: "Estamos orgullosos de la seguridad y la satisfacción de nuestros clientes. Todas las compras de Smurfs están aseguradas por todos nuestros métodos de pago, lo que significa que su información de pago es 100% segura y nuestro sitio web está protegido por SSL para mayor seguridad. También tenemos una garantía por 7 dias en todas nuestras cuentas, por lo tanto, si algo le sucede, ¡está cubierto!",
        },
        2: {
          iconClass: "icon far fa-credit-card",
          title: "¿Cuáles son los métodos de pago?",
          text: "Actualmente aceptamos MercadoPago, y tarjetas de crédito/débito.",
          //  también Criptomonedas como Bitcoin, Ethereum, Litecoin, Cardano, entre otras.',
        },
        3: {
          iconClass: "icon far fa-clock",
          title: "¿Cuánto tiempo demora en ser entregada mi cuenta?",
          text: "El proceso de entrega es INSTANTÁNEO, tan pronto como se realice tu pago se te enviará un correo electrónico con la información de la cuenta. Si no ves el correo electrónico, verificá tus carpetas de correo no deseado y spam.",
        },
        4: {
          iconClass: "icon fas fa-hand-holding-usd",
          title: "¿Cómo podemos vender a precios tan bajos?",
          text: "Mantenemos nuestros precios bajos al asegurarnos de que nuestros costos generales sean bajos. Queremos construir relaciones a futuro con nuestros clientes.",
        },
        5: {
          iconClass: "icon fas fa-heartbeat",
          title: "¿Cuál es su garantía?",
          text: "Nuestra garantía por 7 dias es muy sencilla: si alguna cuenta comprada a través de nosotros sufre una suspensión por cualquier defecto o falla nuestra, se te otorgará una cuenta de compensación, sin cargo.",
        },
        6: {
          iconClass: "icon fas fa-ticket-alt",
          title: "¿Ofrecen cupones? ¿Cómo los aplico?",
          text: "Al elegir un producto estando en la parte de realizar el pago, simplemente ingresa el cupón donde se indica y haz clic en Usar, se te aplicará automáticamente el descuento. Síguenos en nuestras redes para enterarte de los códigos promocionales.",
        },
        7: {
          iconClass: "icon fas fa-medal",
          title: "¿Organizan sorteos?",
          text: "Desde nuestros comienzos en Mayo de 2020, realizamos sorteos MENSUALMENTE con 3 ganadores: el primer puesto recibe 2 cuentas Smurfs Unrankeds, premiando al segundo puesto con 1 cuenta Smurf Unranked.",
        },
      },
      footer: {
        contactUs: "Contacto",
        instagramId: "arcane.smurfs",
        discordText: "Únete a nuestro Discord",
        discordLink: "https://discord.gg/t7A4rMTruG",
        email: "soporte@arcanesmurfs.com",
        disclaimer: "Arcane Smurfs NO está afiliado, sponsoreado, avalado o asociado con League of Legends o Riot Games, Inc. Todos los derechos de autor, marcas, imágenes y marcas de servicio pertenecen a sus respectivos propietarios.",
        disclaimer2: "Aqui puedes elegir el fragmento de skin que quieras que tenga tu cuenta smurf unranked nivel 30+, tenemos las mejores smurfs del mercado, compre ahora.",
      },
      skins: {
        pageTitle: "Arcane Smurfs - Elige tu skin, smurf unranked nivel 30",
        countryMpagoScript: "ar",
        coupon: "Cupón",
        chooseYourRegion: "Elige tu región",
        chooseYourSkin: "Elige tu skin",
        regions: {
          las: "Latinoamérica Sur",
          br: "Brasil",
        },
        selectAChampion: "Selecciona un campeón",
        placeholderChampInput: "nombre de campeón",
        errorNoStock: "ERROR: No hay stock disponible.",
        errorPleaseTryAgain: "ERROR: Por favor intenta otra vez.",
        errorCouponInvalidForQuantity: "ERROR: Cupón inválido para la cantidad del producto.",
        errorCouponExpired: "ERROR: Cupón ha expirado.",
        errorCouponDontExist: "ERROR: Cupón no existe para el producto seleccionado.",
        localCurrency: "pesos argentinos",
        localCurrencySymbol: "$",
        generatePayment: "Generar pago",
        select: "Seleccionar",
        noStock: "Sin stock",
        makeThePayment: "Realiza el pago",
        price: "Precio",
        use: "Usar",
        yourEmail: "Tu email",
        useCouponToGetDiscount: "Usa un cupon para obtener un descuento",
        writeValidEmail: "Escribe un email válido",
      },
    },
  };
  return dictionary[LANGUAGE];
};
