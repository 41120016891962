import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import dict from '../utils/dictionary';

const FAQ = () => {
	const dictionary = dict()['faq'];

	const [collapse, setCollapse] = useState(1);
	const [isOpen, setIsOpen] = useState(true);
	const toggleMenu = (id) => {
		if (collapse === id) {
			setIsOpen(false);
			setCollapse(null);
		} else {
			setIsOpen(true);
			setCollapse(id);
		}
	};
	return (
		<div className='faq' id='faq'>
			<div className='page-margin faq-background' style={{ margin: '0' }}>
				<div className='skins-title'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-12 col-sm-12 col-text-center d-align-center'>
								<h2 className='title' style={{ justifyContent: 'center', display: 'flex', color: '#dadada' }}>
									<span>{dictionary['title']}</span>
								</h2>
							</div>
						</div>
					</div>
				</div>

				<section>
					<div className='innerpage-decor d-none d-xl-block'>
						<div className='innerpage-faq1'>
							<img src={`${process.env.PUBLIC_URL}/assets/images/blitz.webp`} alt='' />
						</div>
						<div className='innerpage-faq2'>
							<img src={`${process.env.PUBLIC_URL}/assets/images/heimer.webp`} alt='' />
						</div>
					</div>
					{/*faq in*/}
					<div className='faq'>
						<div className='container'>
							<div className='row'>
								<div className='col-md-12'>
									<div id='accordion'>
										<div className='card border-theme mb-3 radius-0'>
											<div className='card-header' onClick={() => toggleMenu(1)}>
												<i className={dictionary[1]['iconClass']}></i>

												<a
													onClick={(e) => {
														e.preventDefault();
													}}
													className=''
													href='#'
												>
													{dictionary[1]['title']} <i className='fa fa-angle-down'></i>
												</a>
											</div>

											<Collapse isOpen={collapse === 1 ? isOpen : false}>
												<div>
													<div className='card-body'>{dictionary[1]['text']}</div>
												</div>
											</Collapse>
										</div>

										<div className='card border-theme mb-3 radius-0'>
											<div className='card-header' onClick={() => toggleMenu(2)}>
												<i className={dictionary[2]['iconClass']}></i>
												<a
													onClick={(e) => {
														e.preventDefault();
													}}
													className=''
													href='#'
												>
													{dictionary[2]['title']} <i className='fa fa-angle-down'></i>
												</a>
											</div>
											<Collapse isOpen={collapse === 2 ? isOpen : false}>
												<div>
													<div className='card-body'>{dictionary[2]['text']}</div>
												</div>
											</Collapse>
										</div>
										<div className='card border-theme mb-3 radius-0'>
											<div className='card-header' onClick={() => toggleMenu(3)}>
												<i className={dictionary[3]['iconClass']}></i>
												<a
													onClick={(e) => {
														e.preventDefault();
													}}
													className=''
													href='#'
												>
													{dictionary[3]['title']} <i className='fa fa-angle-down'></i>
												</a>
											</div>
											<Collapse isOpen={collapse === 3 ? isOpen : false}>
												<div>
													<div className='card-body'>{dictionary[3]['text']}</div>
												</div>
											</Collapse>
										</div>
										<div className='card border-theme mb-3 radius-0'>
											<div className='card-header' onClick={() => toggleMenu(4)}>
												<i className={dictionary[4]['iconClass']}></i>
												<a
													onClick={(e) => {
														e.preventDefault();
													}}
													className=''
													href='#'
												>
													{dictionary[4]['title']} <i className='fa fa-angle-down'></i>
												</a>
											</div>
											<Collapse isOpen={collapse === 4 ? isOpen : false}>
												<div>
													<div className='card-body'>{dictionary[4]['text']}</div>
												</div>
											</Collapse>
										</div>
										<div className='card border-theme mb-3 radius-0'>
											<div className='card-header' onClick={() => toggleMenu(5)}>
												<i className={dictionary[5]['iconClass']}></i>
												<a
													onClick={(e) => {
														e.preventDefault();
													}}
													className=''
													href='#'
												>
													{dictionary[5]['title']} <i className='fa fa-angle-down'></i>
												</a>
											</div>
											<Collapse isOpen={collapse === 5 ? isOpen : false}>
												<div>
													<div className='card-body'>{dictionary[5]['text']}</div>
												</div>
											</Collapse>
										</div>
										<div className='card border-theme mb-3 radius-0'>
											<div className='card-header' onClick={() => toggleMenu(6)}>
												<i className={dictionary[6]['iconClass']}></i>
												<a
													onClick={(e) => {
														e.preventDefault();
													}}
													className=''
													href='#'
												>
													{dictionary[6]['title']} <i className='fa fa-angle-down'></i>
												</a>
											</div>
											<Collapse isOpen={collapse === 6 ? isOpen : false}>
												<div>
													<div className='card-body'>{dictionary[6]['text']}</div>
												</div>
											</Collapse>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default FAQ;
